import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

import { Box } from "../../components/Blog/Core";
import PostCard from "../../components/Blog/PostCard";
import Pagination, { PageItem } from "../../components/Blog/Pagination";
import { removeHtmlTags } from "../../lib/stringHelper";

const BlogList = ({ data }) => (
  <>
    {/* <!-- Blog section --> */}

    <Row className="align-items-center justify-content-center">
      {data.map(({ node }) => (
        <Col lg="6" className="mb-5">
          <PostCard
            img={node.image}
            preTitle={node.date}
            title={node.title}
            link={node.slug}
            readMore
          >
            {removeHtmlTags(node.excerpt)}
          </PostCard>
        </Col>
      ))}
    </Row>
    <Box className="d-flex justify-content-start" mt={4}>
      <Pagination>
        <PageItem>
          <FaAngleLeft />
        </PageItem>
        <PageItem>1</PageItem>
        <PageItem>2</PageItem>
        <PageItem>3</PageItem>
        <PageItem>...</PageItem>
        <PageItem>9</PageItem>
        <PageItem>
          <FaAngleRight />
        </PageItem>
      </Pagination>
    </Box>
  </>
);

export default BlogList;
