import React from "react";
import "@redq/reuse-modal/es/index.css";
import SEO from "../components/seo";
import { graphql } from "gatsby";

import { Container, Row, Col } from "react-bootstrap";
import { Section, Title, Text } from "../components/Blog/Core";

import BlogList from "../sections/blog/BlogListSidebar";
import Sidebar from "../sections/blog/Sidebar";
import Layout from "../components/Blog/Layout";

export const query = graphql`
  query MyQuery {
    posts: allBlogPost {
      edges {
        node {
          id
          slug
          status
          title
          type
          modified
          link
          date
          author
          image
          altText
          content
          excerpt
        }
      }
    }
  }
`;

const Blog = ({ data }) => {
  return (
    <React.Fragment>
      <SEO
        title="MARK AI - Blog"
        description="News, tutorials and tips from our top experts, including ex-SMMA founders"
      />

      <Layout>
        <div className="site-wrapper overflow-hidden">
          <Section className="pb-0">
            <div className="pt-5"></div>
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <Title variant="hero">Mark's Blog & News</Title>
                  <Text>News, tutorials and tips from our top experts!</Text>
                </Col>
              </Row>
            </Container>
          </Section>
          <Section>
            <Container>
              <Row>
                <Col lg="8" className="mb-5">
                  <BlogList data={data.posts.edges} />
                </Col>
                <Col lg="4" className="">
                  <Sidebar data={data.posts.edges} />
                </Col>
              </Row>
            </Container>
          </Section>
        </div>
      </Layout>
    </React.Fragment>
  );
};
export default Blog;
